import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  shape,
  string,
  oneOf,
} from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
  createResourceLocatorObject,
  createResourceLocatorString,
  findRouteByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import {
  timestampToDate,
  calculateQuantityFromHours,
  calculateQuantityFromDays,
} from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/UI.duck';
import {
  initializeCardPaymentData,
  initializeAfterpayPaymentData,
} from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  CustomUserNav,
  ImageCarousel,
  Button,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { ReactComponent as NOINTERNET } from '../../assets/noInternet.svg';
import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  fetchProductTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import ImageGallery from 'react-image-gallery';
import { withViewport } from '../../util/contextHelpers';
import { ReactComponent as AddToFavBtn } from '../../assets/Wishlist.svg';
import { ReactComponent as ArrowLeft } from '../../assets/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/ArrowRight.svg';
import ActionBarMaybe from './ActionBarMaybe';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { addCart } from '../CartPage/CartPage.duck';
import { ReactComponent as ArrowRightExtended } from '../../assets/ArrowRightExtended.svg';
import Lottie from 'react-lottie-player';
import lottieJson from '../../components/siuhuu-lottie.json';
import {
  dateField,
  deliveryMethodOptions,
  returnPolicyOptions,
} from '../../marketplace-custom-config';
import lottieImgJson from '../../components/skeleton-loader.json';
import { RWebShare } from 'react-web-share';
import { ReactComponent as ShareIcon } from '../../assets/Share.svg';
import classNames from 'classnames';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;
const LoadingTimeout = 2000;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      isFavourite: null,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isSubmittingForm: false,
      isSubmitError: false,
      isLoading: true,
      shouldShowCustomQuotePublishModal: false,
    };
    this.isFavouriteCalledRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  getBookingData = values => {
    const {
      bookingDates,
      deliveryMethod,
      quantity: productQty,
      shippingType,
      size,
      unit,
    } = values;

    const { startDate, endDate } = bookingDates || {};
    const bookingStart = startDate ?? new Date();
    const bookingEnd = endDate ?? new Date();

    const productQtyInt =
      !!startDate && !!endDate
        ? calculateQuantityFromDays(bookingStart, bookingEnd)
        : parseInt(productQty);

    if (isNaN(productQtyInt)) return {};
    const bookingDetails = {
      bookingStart: bookingStart.toISOString(),
      bookingEnd: bookingEnd.toISOString(),
      quantity: productQtyInt,
      unitType: unit,
    };
    if (
      typeof deliveryMethod === 'string' &&
      typeof size === 'string' &&
      typeof shippingType === 'string'
    ) {
      bookingDetails.deliveryMethod = deliveryMethod;
      bookingDetails.size = size;
      bookingDetails.shippingType = shippingType;
    }
    bookingDetails.isRental = false;
    bookingDetails.isRentalSubType = dateField.includes(unit);
    return bookingDetails;
  };

  handleSubmit(values) {
    const {
      location,
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onInitializeAfterpayPaymentData,
      currentUser,
    } = this.props;
    if (this.state.isSubmittingForm) return;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    if (!currentUser || !currentUser.id) {
      const { pathname, search, hash } = location;
      const redirectUrl = {
        pathname: '/login',
        state: {
          from: `${pathname}${search}${hash}`,
        },
      };
      history.replace(redirectUrl);
      return;
    }
    if (!listing || !listing.id) return;

    this.setState({ isSubmittingForm: true, isSubmitError: false });
    const bookData = this.getBookingData(values);
    const { isRental, isRentalSubType = false, ...restBookingData } = bookData;
    const bookingData = {
      ...restBookingData,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: new Date(restBookingData.bookingStart),
        bookingEnd: new Date(restBookingData.bookingEnd),
      },
      confirmPaymentError: null,
    };

    const isPlatformShipping =
      listing.attributes.publicData?.shippingType ===
      deliveryMethodOptions.automaticShipping.value;

    const axiosBody = {
      userId: currentUser.id.uuid,
      bookingDetails: [
        {
          listingId: listing.id.uuid,
          ...restBookingData,
          metadata: {
            shippingOption: isPlatformShipping
              ? deliveryMethodOptions.automaticShipping.value
              : deliveryMethodOptions.manualShipping.value,
          },
        },
      ],
      isRental,
      isRentalSubType,
    };
    const url = `${apiBaseUrl()}/api/bookingdetails/add`;
    axios
      .post(url, axiosBody)
      .then(_ => {
        const saveToSessionStorage = !this.props.currentUser;
        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName(
          'CheckoutPage',
          routes
        );

        callSetInitialValues(
          setInitialValues,
          initialValues,
          saveToSessionStorage
        );

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();
        onInitializeAfterpayPaymentData();

        // Redirect to CheckoutPage

        this.setState({ isSubmittingForm: false });
        history.push(
          createResourceLocatorObject(location, 'CheckoutPage', routes, {
            id: listing.id.uuid,
            slug: createSlug(listing.attributes.title),
          })
        );
      })
      .catch(err => {
        this.setState({ isSubmittingForm: false, isSubmitError: true });
        console.error(err);
      });
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;
    const listing = getListing(listingId);

    onSendEnquiry(listing, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString(
            'OrderDetailsPage',
            routes,
            { id: txId.uuid },
            {}
          )
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  isCurrentListingFavourite = async (userId, listingId) => {
    const currentUserFavourites = await axios.post(
      `${apiBaseUrl()}/api/is-listing-favourite`,
      {
        userId,
        listingId,
      },
      { withCredentials: true }
    );
    return currentUserFavourites;
  };
  componentDidMount() {
    this.isFavouriteCalledRef.current = false;
  }
  componentDidUpdate() {
    const {
      currentUser,
      params: rawParams,
      getOwnListing,
      getListing,
      location,
    } = this.props;
    const listingId = new UUID(rawParams.id);
    if (
      currentUser &&
      this.state.isFavourite === null &&
      this.isFavouriteCalledRef.current === false
    ) {
      this.isFavouriteCalledRef.current = true;
      this.isCurrentListingFavourite(currentUser.id.uuid, listingId.uuid).then(
        res => {
          this.setState(prevState => ({
            ...prevState,
            isFavourite: res.data,
          }));
        }
      );
    }
    if (this.state.isLoading) {
      const isPendingApprovalVariant =
        rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
      const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
      const currentListing =
        isPendingApprovalVariant || isDraftVariant
          ? ensureOwnListing(getOwnListing(listingId))
          : ensureListing(getListing(listingId));

      const isCustomQuote = currentListing.attributes.publicData.isCustomQuote;
      const { search } = location;
      const queryParams = parse(search);
      if (
        isCustomQuote &&
        queryParams.cq &&
        !this.state.shouldShowCustomQuotePublishModal
      ) {
        this.setState({ shouldShowCustomQuotePublishModal: true });
      }
      if (!!currentListing.id) {
        setTimeout(() => this.setState({ isLoading: false }), LoadingTimeout);
      }
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      onFetchProductTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      history,
      viewport,
      cartAddInProgress,
      cartAddError,
      onAddCart,
      lineItemLabel,
    } = this.props;
    const { width } = viewport;

    const { search } = location;
    const queryParams = parse(search);

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant =
      rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug =
      rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage =
      pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return (
        <NamedRedirect
          name="ListingPage"
          params={params}
          search={location.search}
        />
      );
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage
        id="ListingPage.bookingTitle"
        values={{ title: richTitle }}
      />
    );

    const topbar = <TopbarContainer showSearchInMobile={true} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.noInternetDiv}>
                <NOINTERNET className={css.noInternet} />
                <p className={css.errorText}>
                  <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
                </p>
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              {/* <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p> */}
              <div className={css.loadingBody}>
                <Lottie
                  loop
                  className={css.loadingIcon}
                  animationData={lottieJson}
                  play
                  style={{ width: 80, height: 80 }}
                />
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable &&
      currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser =
      authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed =
        currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        if (values.quantity === '10+') {
          const newValues = {
            ...values,
            quantity: values.typedQuantity.toString(),
          };
          this.handleSubmit(newValues);
        } else {
          this.handleSubmit(values);
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const yogaStylesOptions = findOptionsForSelectFilter(
      'yogaStyles',
      filterConfig
    );
    const certificateOptions = findOptionsForSelectFilter(
      'certificate',
      filterConfig
    );
    const hasImages =
      currentListing?.images && currentListing?.images?.length > 0;

    const viewPhotosButton = hasImages ? (
      <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
        <FormattedMessage
          id="ListingPage.viewImagesButton"
          values={{ count: currentListing.images.length }}
        />
      </button>
    ) : null;
    const images = currentListing.images
      ? currentListing?.images.map(i => ({
          original: i.attributes?.variants?.['scaled-large']?.url,
          thumbnail: i.attributes?.variants?.['scaled-small']?.url,
          originalClass: css.mainimg,
          thumbnailClass: css.thumbnail,
        }))
      : [];

    const galleryMaybe = this.state.isLoading ? (
      <div className={css.loadingImgBody}>
        <Lottie
          loop
          className={css.loadingImgIcon}
          animationData={lottieImgJson}
          play
          // style={{ width: 200, height: 200 }}
        />
      </div>
    ) : (
      <div className={css.gallerydiv}>
        <ImageGallery
          items={images}
          // renderItem={renderItem}
          showPlayButton={false}
          renderCustomControls={() => {
            return (
              <>
                <div className={css.cstmctrl}>
                  {!isOwnListing ? (
                    this.state.isFavourite ? (
                      <span className={css.roundBtn}>
                        <AddToFavBtn
                          className={css.unfavBtn}
                          onClick={e => {
                            if (!currentUser) {
                              return history.push({
                                pathname: '/login',
                                state: {
                                  from: `${location.pathname}${location.search}${location.hash}`,
                                },
                              });
                            }
                            axios
                              .post(
                                `${apiBaseUrl()}/api/toggle-favourites`,
                                {
                                  userId: currentUser?.id?.uuid,
                                  listingId: listingId?.uuid,
                                },
                                {
                                  withCredentials: true,
                                }
                              )
                              .then(res => {
                                this.setState(state => ({
                                  ...state,
                                  isFavourite: res.data,
                                }));
                              })
                              .catch(err => console.error(err));
                          }}
                        />
                      </span>
                    ) : (
                      <span className={css.roundBtn}>
                        <AddToFavBtn
                          className={css.favBtn}
                          onClick={e => {
                            if (!currentUser) {
                              return history.push({
                                pathname: '/login',
                                state: {
                                  from: `${location.pathname}${location.search}${location.hash}`,
                                },
                              });
                            }
                            axios
                              .post(
                                `${apiBaseUrl()}/api/toggle-favourites`,
                                {
                                  userId: currentUser?.id?.uuid,
                                  listingId: listingId?.uuid,
                                },
                                {
                                  withCredentials: true,
                                }
                              )
                              .then(res =>
                                this.setState(state => ({
                                  ...state,
                                  isFavourite: res.data,
                                }))
                              );
                          }}
                        />
                      </span>
                    )
                  ) : null}
                </div>
                <div className={css.viewPhotoBtnDiv}>{viewPhotosButton}</div>
              </>
            );
          }}
          renderRightNav={(onClick, disabled) => (
            <button
              onClick={onClick}
              disabled={disabled}
              className="image-gallery-icon image-gallery-right-nav"
            >
              <ArrowRight className={css.arrowBtn} />
              {/* <MdOutlineKeyboardArrowRight className="image-gallery-svg" /> */}
            </button>
          )}
          renderLeftNav={(onClick, disabled) => (
            <button
              onClick={onClick}
              disabled={disabled}
              className="image-gallery-icon image-gallery-left-nav"
            >
              <ArrowLeft className={css.arrowBtn} />
              {/* <MdOutlineKeyboardArrowLeft className="image-gallery-svg" /> */}
            </button>
          )}
          additionalClass={css.wrapperClassName}
          // thumbnailPosition={width <= 767 ? 'bottom' : 'right'}
          thumbnailPosition={'bottom'}
          showFullscreenButton={false}
          // disableThumbnailScroll={true}
        />
      </div>
    );
    const actionBar = currentListing.id ? (
      <div onClick={e => e.stopPropagation()} className={css.actionBarDiv}>
        <ActionBarMaybe
          isOwnListing={isOwnListing}
          listing={currentListing}
          editParams={{
            id: listingId.uuid,
            slug: listingSlug,
            type: listingType,
            tab: listingTab,
          }}
        />
      </div>
    ) : null;

    //for the RWebShare text data
    const singleWord = title.split(' ');
    for (var i = 0; i < singleWord.length; i++) {
      singleWord[i] =
        singleWord[i].charAt(0).toUpperCase() + singleWord[i].slice(1);
    }
    const sharableText = singleWord.join(' ');
    // console.log(656, sharableText);

    const urlShr = typeof window !== 'undefined' ? window.location : null;

    const currentListingLoaded = !!currentListing.id;
    const currentUserLoaded = currentUser && currentUser.id;
    const { isCustomQuote, customerEmail = '' } = publicData || {};
    if (currentListingLoaded && isCustomQuote && currentUserLoaded) {
      if (
        !isAuthenticated ||
        (!isOwnListing && customerEmail !== currentUser.attributes.email)
      ) {
        return <NotFoundPage />;
      }
    }

    const routes = routeConfiguration();
    const SectionReturnPolicyMaybe = props => {
      const { publicData } = props;
      const { returnPolicy } = publicData || {};
      const defaultReturnPolicySelector = returnPolicyOptions.find(
        e => e.key === returnPolicy
      ); 
      return (
        <>
          {!!returnPolicy && (
            <div className={css.featureDetailForReturn}>
              <span>Return Policy</span>
              <span>{defaultReturnPolicySelector?.label}</span>
            </div>
          )}
        </>
      );
    };
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            {topbar}
            <CustomUserNav history={history} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.contentContainer}>
                <div className={css.topDiv}>
                  <button
                    className={css.backBtn}
                    onClick={() => history.goBack()}
                  >
                    <ArrowRightExtended />
                    Back
                  </button>
                  <RWebShare
                    data={{
                      text: `${sharableText}`,
                      url: ` ${urlShr} `,
                      title: 'Share with',
                    }}
                  >
                    <div className={css.shrBtnMobile}>
                      <span>
                        <ShareIcon />
                      </span>
                      Share
                    </div>
                  </RWebShare>
                </div>
                {actionBar}

                <div className={css.imgGalleryDiv}>{galleryMaybe}</div>

                <div className={css.mainContent}>
                  <SectionDescriptionMaybe
                    description={description}
                    title={title}
                  />
                  <SectionFeaturesMaybe
                    options={yogaStylesOptions}
                    publicData={publicData}
                  />
                  {/* <SectionReturnPolicyMaybe publicData={publicData} /> */}

                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <div className={css.reviewDiv}>
                    <SectionReviews
                      reviews={reviews}
                      fetchReviewsError={fetchReviewsError}
                    />
                  </div>
                </div>
              </div>

              <div className={css.bookingPanelDiv}>
                <RWebShare
                  data={{
                    text: `${title}`,
                    url: ` ${urlShr} `,
                    title: 'Share with',
                  }}
                >
                  <div className={css.shrBtn}>
                    <span>
                      <ShareIcon />
                    </span>
                    Share
                  </div>
                </RWebShare>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  onFetchProductTransactionLineItems={
                    onFetchProductTransactionLineItems
                  }
                  lineItems={lineItems}
                  lineItemLabel={lineItemLabel}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentAuthor={currentAuthor}
                  params={params}
                  showContactUser={showContactUser}
                  onContactUser={this.onContactUser}
                  cartAddInProgress={cartAddInProgress}
                  cartAddError={cartAddError}
                  onAddCart={onAddCart}
                  currentUser={currentUser}
                  reviews={reviews}
                  fetchReviewsError={fetchReviewsError}
                  isSubmittingForm={this.state.isSubmittingForm}
                  isSubmitError={this.state.isSubmitError}
                />
              </div>
            </div>
            <Modal
              id="ListingPage.confirmModal"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.shouldShowCustomQuotePublishModal}
              onClose={() => {
                this.setState({ shouldShowCustomQuotePublishModal: false });
                history.push(
                  createResourceLocatorString(
                    'ListingPage',
                    routes,
                    {
                      id: currentListing.id.uuid,
                      slug: createSlug(currentListing.attributes.title),
                    },
                    {}
                  )
                );
              }}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <div className={classNames(css.editor, css.successMsg)}>
                  <h3 className={css.termsHeading}>
                    <FormattedMessage id="ProductOrderForm.rtqPublishSuccessHeading" />
                  </h3>
                  <FormattedMessage id="ProductOrderForm.rtqPublishSuccessMessage" />
                </div>

                <Button
                  className={classNames(css.submitBtn, css.okBtn)}
                  type="button"
                  onClick={() => {
                    this.setState({ shouldShowCustomQuotePublishModal: false });
                    history.push(
                      createResourceLocatorString(
                        'ListingPage',
                        routes,
                        {
                          id: currentListing.id.uuid,
                          slug: createSlug(currentListing.attributes.title),
                        },
                        {}
                      )
                    );
                  }}
                >
                  <FormattedMessage id="ProductOrderForm.rtqPublishSuccess" />
                </Button>
              </div>
            </Modal>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
            <Modal
              id="ListingPage.imageCarousel"
              scrollLayerClassName={css.carouselModalScrollLayer}
              containerClassName={css.carouselModalContainer}
              lightCloseButton
              isOpen={this.state.imageCarouselOpen}
              onClose={() => this.setState({ imageCarouselOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <ImageCarousel
                images={currentListing.images}
                imageVariants={[
                  'scaled-small',
                  'scaled-medium',
                  'scaled-large',
                  'scaled-xlarge',
                ]}
              />
            </Modal>
            {/* <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    listingCertificate={
                      publicData ? publicData.certificate : null
                    }
                    certificateOptions={certificateOptions}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  /> */}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onInitializeAfterpayPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  onFetchProductTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    lineItemLabel,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { cartAddInProgress, cartAddError } = state.CartPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    cartAddInProgress,
    cartAddError,
    lineItemLabel,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) => {
    return dispatch(
      fetchTransactionLineItems(bookingData, listingId, isOwnListing)
    );
  },
  onFetchProductTransactionLineItems: (
    bookingData,
    listingId,
    isOwnListing
  ) => {
    return dispatch(
      fetchProductTransactionLineItems(bookingData, listingId, isOwnListing)
    );
  },
  onSendEnquiry: (listing, message) => dispatch(sendEnquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onInitializeAfterpayPaymentData: () =>
    dispatch(initializeAfterpayPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onAddCart: cartDetails => dispatch(addCart(cartDetails)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
